import React, { useEffect, useRef, useState } from "react";
import { getActualWidthOfChars } from "src/utils";
import { Table } from "antd";
const RankTable = ({
  parentProps,
  handleClickItem,
  detailParams,
  schemeName,
  tableParams: {
    departmentId,
    positionId,
    recordsId,
    dateCycle,
    cycleType,
    cycleValue,
    structId,
    schemeId,
  },
}) => {
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(200);
  const [pageNum, setPageNum] = useState(1);
  const [sortIndexKey, setSortIndexKey] = useState("");
  const [sortMode, setSortMode] = useState(0);
  const [columns, setColumns] = useState([]);
  const [average, setAverage] = useState({});
  const [total, setTotal] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const handleTableChange = (pagination, _filters, sorter) => {
    setPageSize(pagination.pageSize);
    setPageNum(pagination.current);
    console.log("sorter", sorter);
    setSortIndexKey(sorter.field);
    if (sorter.order) setSortMode(sorter.order === "ascend" ? 1 : 2);
    else setSortMode(0);
  };
  useEffect(() => {
    setLoading(true);
    // 监听到方案id 变了加载数据
    // 获取表头
    parentProps.ajax
      .get(`/api/achievement/scheme/queryTitle/${recordsId}`)
      .then((res) => {
        if (res.data && res.data.length) {
          const {
            struct: { nodeId: structId },
          } = res.map;
          const titleList = res.data.map((o, i) => {
            const col = {
              ...o,
              dataIndex: o.field,
              key: o.field,
              sorter: o.sortFlag,
              ellipsis: true,
              width: i < 2 ? 100 : getActualWidthOfChars(o.title) + 30,
            };
            const needFixedColumn = [
              "dataId",
              "dataName",
              "ranking",
              "finalScore",
              "position",
              "departmentName",
            ];
            if (needFixedColumn.includes(o.field)) {
              col.fixed = "left";
            }
            if (o.openPage) {
              const idx = o.field.split("-Value")[0];
              col.render = (v, r) => (
                <span
                  style={{ color: "blue", cursor: "pointer" }}
                  onClick={() =>
                    window.open(
                      `/front/target-detail?name=${r.dataName}&structId=${structId}&indexIds=${idx}&userId=${r.dataId}&dateCycle=${dateCycle}&schemeRecordsId=${recordsId}`,
                      "_blank"
                    )
                  }
                >
                  {v}
                </span>
              );
            }
            if (o.field === "dataName") {
              col.render = (v, r) => (
                <span
                  style={{ color: "blue", cursor: "pointer" }}
                  onClick={() => {
                    const [startTime, endTime] = dateCycle.split("~");
                    window.open(
                      `/front/achievement-detail?cycleType=${cycleType}&cycleValue=${cycleValue}&employId=${r.dataId}&schemeId=${schemeId}&startTime=${startTime}&endTime=${endTime}&structId=${structId}&schemeName=${schemeName}&employeeName=${r.dataName}&recordsId=${recordsId}`
                    );
                    // handleClickItem({
                    //   employId: r.dataId,
                    //   employeeName: r.dataName,
                    // });
                  }}
                >
                  {v}
                </span>
              );
            }
            return col;
          });
           // 这里需要去处理前六列数据
           const [dataId, dataName,position,departmentName,ranking,finalScore,...rest] = titleList;
           const finalList =[dataName,ranking,finalScore,dataId,departmentName,position,...rest]
           setColumns(finalList.sort((a,b)=>a.sortNo-b.sortNo));
        }
      });
    // 获取数据
    parentProps.ajax
      .post(`/api/achievement/scheme/querySchemeCalculateDetail`, {
        recordsId,
        positionId,
        departmentId,
        pageNum,
        pageSize,
        sortMode,
        sortField: sortIndexKey,
      })
      .then((res) => {
        const { data = [], count } = res.data || {};
        setDataSource([...data]);
        setTotal(count);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  }, [
    recordsId,
    positionId,
    departmentId,
    pageNum,
    pageSize,
    sortIndexKey,
    sortMode,
  ]);
  return (
    <Table
      size="small"
      bordered
      loading={loading}
      columns={columns}
      dataSource={dataSource}
      rowKey="employeeId"
      onChange={handleTableChange}
      scroll={{ x: "max-content", y: "calc(100vh - 380px)" }}
      pagination={{
        pageNum,
        pageSize,
        pageSizeOptions: [200, 500, 1000],
        showSizeChanger: true,
        total,
        showTotal: (tot) => `共${tot}条数据`,
      }}
    />
  );
};

export default RankTable;
